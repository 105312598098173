import * as contentful from "contentful";
import Vue from "vue";

const client = contentful.createClient({
  space: "wonb939ksskz",
  accessToken: "hFuBr6RNh0nvdmfbgeCwtknN1A4HmAkSxREVCFeVkHc",
  environment: "master"
});

Vue.use({
  install(Vue) {
    Vue.prototype.$contentful = client;
  }
});

export const loadApartment = async id => {
  const apartment = await client
    .getEntries({
      content_type: "apartment",
      "fields.code": id,
      include: 10
    })
    .then(q => q.items[0].fields);

  return apartment;
};
