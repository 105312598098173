<template>
  <div class="home">
    <Header class="header" />

    <div class="grid">
      <div class="login-block">
        <div class="instructions">
          Inserisci il codice che trovi nel biglietto di benvenuto
        </div>

        <div class="code" :class="{ focus: focus || code.length > 0 }">
          <input
            v-model="code"
            type="text"
            @focus="focus = true"
            @blur="focus = false"
          />
        </div>

        <router-link :to="'/dove-trovo-il-codice'"
          >Dove trovo il codice d’accesso</router-link
        >

        <button class="login" :class="{ canLogin }" @click="login">
          entra
        </button>
        <button class="qr" @click="$router.push('/scan')">
          scansiona qr code
        </button>
      </div>
      <!-- <div class="where-code">
        <div class="description">
          Il codice d’accesso è applicato su un’etichetta all’interno dello
          sportello del contatore della luce presente in entrata.
        </div>

        <img src="//via.placeholder.com/300x200" alt="x" class="picture" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: "Home",
  components: {
    Header
  },
  data: () => ({
    code: "",
    focus: false
  }),
  computed: {
    canLogin: {
      get() {
        return this.code.length > 1;
      }
    }
  },
  methods: {
    login() {
      this.$router.push(`/${this.code}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 35px;

  .header {
    margin-bottom: 62px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr;
    }

    .login-block {
      .instructions {
        color: black;
        opacity: 0.55;
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        width: 300px;
        margin: 0 auto;
        margin-bottom: 77px;
      }

      .code {
        position: relative;
        width: 300px;
        margin: 0 auto;

        input {
          border: none;
          border-bottom: 1px solid var(--black);
          background: transparent;
          padding: 0;
          padding-bottom: 11px;
          width: 300px;
          display: block;
          margin: 0 auto;
          margin-bottom: 47px;
          color: var(--black);
          font-size: 15px;
          line-height: 19px;
          font-weight: bold;

          &:focus {
            outline: none;
            border-bottom: 2px solid var(--light-brown);
            margin-bottom: 46px;
          }
        }

        &::after {
          content: "Codice di accesso";
          color: var(--black);
          font-size: 15px;
          line-height: 19px;
          font-weight: bold;
          text-transform: none;
          position: absolute;
          z-index: 999;
          left: 0;
          top: 0;
          transition: all 0.25s ease-in-out;
        }

        &.focus {
          &::after {
            transform: translateY(-30px);
            opacity: 0.5;
          }
        }
      }

      a {
        color: var(--light-brown);
        text-decoration: none;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        display: block;
        font-weight: bold;
        margin-bottom: 64px;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      .login {
        background: var(--disabled-light-brown);
        border: none;
        color: var(--white);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 18px 27px;
        border-radius: 72px;
        width: 300px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
        pointer-events: none;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &.canLogin {
          pointer-events: all;
          background: var(--light-brown);
        }

        &:hover {
          background: var(--brown);
        }
      }

      .qr {
        background: var(--white);
        border: none;
        color: var(--black);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 18px 27px;
        border-radius: 72px;
        width: 300px;
        display: block;
        margin: 0 auto;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
      }
    }

    .where-code {
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
      }

      .description {
        font-weight: 450;
        font-size: 15px;
        line-height: 19px;
        width: 300px;
        margin: 0 auto;
        margin-bottom: 44px;
      }

      .picture {
        display: block;
        margin: 0 auto;
        border-radius: 13px;
        filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.12));
      }
    }
  }
}
</style>
