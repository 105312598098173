import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dove-trovo-il-codice",
    name: "WhereCode",
    component: () =>
      import(/* webpackChunkName: "where-code" */ "../views/WhereCode.vue")
  },
  {
    path: "/scan",
    name: "Scan",
    component: () =>
      import(/* webpackChunkName: "where-code" */ "../views/Scan.vue")
  },
  {
    path: "/:code",
    name: "Code",
    component: () =>
      import(/* webpackChunkName: "code" */ "../views/Code/index.vue"),
    props: true
  },
  {
    path: "/:code/documenti-amministrativi",
    name: "CodeDocs",
    component: () =>
      import(/* webpackChunkName: "code" */ "../views/Code/Docs.vue"),
    props: true
  },
  {
    path: "/:code/condominio",
    name: "CodeCondo",
    component: () =>
      import(/* webpackChunkName: "code" */ "../views/Code/Condo.vue"),
    props: true
  },
  {
    path: "/:code/uso-manutenzione",
    name: "CodeMaintenance",
    component: () =>
      import(/* webpackChunkName: "code" */ "../views/Code/Maintenance.vue"),
    props: true
  },
  {
    path: "/:code/utenze",
    name: "CodeBills",
    component: () =>
      import(/* webpackChunkName: "code" */ "../views/Code/Bills.vue"),
    props: true
  },
  {
    path: "/:code/fornitori",
    name: "CodeSuppliers",
    component: () =>
      import(/* webpackChunkName: "code" */ "../views/Code/Suppliers.vue"),
    props: true
  },
  {
    path: "/:code/contatta-amministratore",
    name: "CodeContact",
    component: () =>
      import(/* webpackChunkName: "code" */ "../views/Code/Contact.vue"),
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
